import {
  BenefitBonus,
  BenefitDayoff,
  BenefitEnglish,
  BenefitFlash,
  BenefitGympass,
  BenefitHomeoffice,
  BenefitRefund,
  BenefitTimeoff,
  BenefitTransport,
} from '../../molecules/Icons'

export const iconComponentMap = new Map([
  ['Flash', BenefitFlash],
  ['Bônus por metas e performance', BenefitBonus],
  ['Aulas de inglês', BenefitEnglish],
  ['Auxílio home office', BenefitHomeoffice],
  ['Auxílio mobilidade', BenefitTransport],
  ['Gympass', BenefitGympass],
  ['Day Off', BenefitDayoff],
  ['Reembolso de cursos', BenefitRefund],
  ['Descanso remunerado', BenefitTimeoff],
])
