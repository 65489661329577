import parse from 'html-react-parser'
import React, { useState } from 'react'
import './styles.scss'

const Accordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className="accordion">
      <button onClick={handleToggle} className="accordion__button">
        <h3>{title}</h3>
        <span>{isOpen ? '-' : '+'}</span>
      </button>
      <div className={`accordion__content${isOpen ? '--active' : ''}`}>
        {parse(content)}
      </div>
    </div>
  )
}

export default Accordion
