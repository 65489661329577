import React from 'react'
import useStaticImage from '../../../hooks/useStaticImage'
import SectionContainer from '../../molecules/SectionContainer'
import benefitMap from './benefitMap.json'
import { iconComponentMap } from './iconComponentMap'
import './styles.scss'

const Benefits = () => {
  const [getImage] = useStaticImage()

  return (
    <SectionContainer>
      <div className="benefits">
        <h2 className="benefits__title">Benefícios que só quem é DR_ tem:</h2>
        <div className="benefits__item-list">
          {benefitMap.map((benefit) => {
            const Icon = iconComponentMap.get(benefit.contentfulKey)

            return (
              <div key={benefit.text} className="benefits__item-list__item">
                <div className="benefits__item-list__item__icon">
                  <Icon />
                </div>
                <p className="benefits__item-list__item__text">{benefit.text}</p>
              </div>
            )
          })}
        </div>
      </div>
    </SectionContainer>
  )
}

export default Benefits
