import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { createClient } from 'contentful'
import { useEffect, useState } from 'react'

export function formatFAQObject(FAQ) {
  const {
    fields: { question, answer },
    sys: { id, createdAt },
  } = FAQ

  const answerHtml = documentToHtmlString(answer)

  const formattedFAQ = {
    id,
    question,
    answer: answerHtml,
    date: createdAt,
  }

  return formattedFAQ
}

const useContentfulFAQ = () => {
  const [questions, setQuestions] = useState([])

  useEffect(() => {
    const contentfulClient = createClient({
      space: process.env.CONTENTFUL_CLIENT_SPACE,
      environment: process.env.CONTENTFUL_CLIENT_ENV,
      accessToken: process.env.CONTENTFUL_CLIENT_ACCESS_TOKEN,
    })

    contentfulClient
      .getEntries({ content_type: 'faqQuestion' })
      .then(({ items: contentfulFAQ }) => {
        const formattedFAQ = contentfulFAQ.map((FAQ) => {
          return formatFAQObject(FAQ)
        })

        setQuestions(formattedFAQ)
      })
      .catch((err) => err)
  }, [])

  return questions
}

export default useContentfulFAQ
